import { trigger, style, animate, transition } from "@angular/animations";

export const fade = trigger("fade", [
  transition(":enter", [
    style({ opacity: 0 }),
    animate("1000ms", style({ opacity: 1 })),
  ]),
  transition(":leave", [animate("1000ms", style({ opacity: 0 }))]),
]);


export const fadeInOut = trigger("fadeInOut", [
  transition(":enter", [
    style({ opacity: 0 }),
    animate("300ms", style({ opacity: 1 })),
  ]),
  transition(":leave", [
    animate("300ms", style({ opacity: 0 })),
  ]),
]);







